<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add/Edit Estimate</p>
        <button class="delete" aria-label="close" (click)="close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
            <label class="label">Name</label>
            <div class="control">
                <input class="input is-primary" type="text" [(ngModel)]="estimate.name"/>
            </div>
        </div>
        <div class="field">
            <label class="label">Amount</label>
            <div class="control">
                <input type="number" class="input is-primary number1" [(ngModel)]="estimate.amount"/>
            </div>
        </div>
        <div class="field">
            <label class="label">Type</label>
            <div class="control">
                <div class="select is-primary">
                    <select name="type" id="type" [(ngModel)]="estimate.type">
                        <option *ngFor="let type of data.transactionTypes" [value]="type.key">{{type.type}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="field">
            <label class="label">Account</label>
            <div class="control">
                <div class="select is-primary">
                    <select name="type" id="type" [(ngModel)]="estimate.accountId">
                        <option *ngFor="let account of accounts" [value]="account.id">{{account.name}}</option>
                    </select>
                </div>
            </div>
        </div>        
        <div class="field" *ngIf="estimate.type==='T'">
            <label class="label">To Account</label>
            <div class="control">
                <div class="select is-primary">
                    <select name="type" id="type" [(ngModel)]="estimate.toAccountId">
                        <option *ngFor="let account of accounts" [value]="account.id">{{account.name}}</option>
                    </select>
                </div>
            </div>
        </div>        
        <div class="field">
            <label class="label">Frequency</label>
            <div class="control">
                <div class="select is-primary">
                    <select name="type" id="type" [(ngModel)]="estimate.frequency.mode">
                        <option *ngFor="let type of data.frequencyTypes" [value]="type.key">{{type.type}}</option>
                    </select>
                </div>
            </div>
        </div> 
        <div class="field">
            <label class="label">Dates</label>
            <div class="control">
                <div style="width: 8em;display: inline-flex;margin-right: 4px;">
                <dp-date-picker [(ngModel)]="startDate"  theme="dp-material" [config]="dpConfig" placeholder="Start Date"></dp-date-picker>
                </div>
                <div style="width: 8em;display: inline-flex;" *ngIf="estimate.frequency.mode!='O'">
                <dp-date-picker [(ngModel)]="endDate"  theme="dp-material" [config]="dpConfig" placeholder="End Date"></dp-date-picker>
                </div>
                <div style="width: 8em;display: inline-flex;margin-right: 4px;">
                    <dp-date-picker [(ngModel)]="lastTransDate"  theme="dp-material" [config]="dpConfig" placeholder="Last Trans Date"></dp-date-picker>
                </div>                
            </div>
        </div>
        <div class="field" *ngIf="estimate.frequency.mode==='W'">
            <label class="label">Days of the Week</label>
            <div class="control">
                <div class="checkbox-wrapper" *ngFor="let day of dayOfWeek"><label class="checkbox">{{day.code}}<br/><input type="checkbox" class="is-primary checkbox" [(ngModel)]="day.selected"/></label></div>
            </div>
            <div class="control every-week">Every
                <select name="type" id="type" [(ngModel)]="estimate.frequency.everyNWeeks" class="every-week">
                    <option *ngFor="let i of [1,2,3,4]" [value]="i">{{i}}</option>
                </select> weeks
            </div>
        </div>  
        <div class="field" *ngIf="estimate.frequency.mode==='M'">
            <label class="label">Months of the Year <a href="#" (click)="selectAllMonths();">(Select All)</a></label>
            <div class="control">
                <div class="checkbox-wrapper" *ngFor="let month of monthOfYear"><label class="checkbox">{{month.code}}<br/><input type="checkbox" class="is-primary checkbox" [(ngModel)]="month.selected"/></label></div>
            </div>
        </div> 
        <div class="field" *ngIf="estimate.frequency.mode==='M'">
            <label class="label">Day(s) of Month</label>
            <div class="control">
                <input type="text" class="input is-primary number-small" [(ngModel)]="daysOfMonth"/>
            </div>
        </div>         
      </section>
      <section *ngIf="errorMessage!=null">
            <div class="notification is-danger is-light" >
                <button class="delete" (click)="clearError()"></button>
                {{errorMessage}}
            </div> 
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="saveEstimate()">Save</button>
        <button class="button is-danger" (click)="deleteEstimate()" *ngIf="estimate.id!=null">Delete</button>
        <button class="button" (click)="close()">Cancel</button>
      </footer>
    </div>
  </div>