import { Injectable } from '@angular/core';
import { from, Observable, of} from 'rxjs';
import { ACCOUNTS } from './mock-accounts';
import { Account } from './account';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  accountRef: AngularFireList<any>;
  
  constructor(private db: AngularFireDatabase) { 
    this.accountRef=db.list("accounts");
  }

  getAccounts(): Observable<any[]> {
    return this.accountRef.snapshotChanges().pipe(
      map((changes: any[]) => 
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    );
  }

  addAccount(account: Account): Observable<any> {
    return from(this.db.list('accounts').push(this.stripId(account))).pipe(first());
  }

  stripId(account: Account):any {
    let fbAcct: any={...account};
    delete fbAcct.id;
    return fbAcct;
  }
  saveAccount(account: Account): void {
    let id = account.id;
    this.db.object(`accounts/${id}`).set(this.stripId(account));
  }
  
  deleteAccount(id: string): void {
    this.db.object(`accounts/${id}`).remove();
  }
}
