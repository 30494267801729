import { Component, OnInit } from '@angular/core';
import { Account } from '../account';
import { AccountService } from '../account-service';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent implements OnInit {
  faCheck = faCheck;
  accounts: Account[]=[];
  showAddDialog: boolean = false;
  account!: Account;

  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
    this.accountService.getAccounts().subscribe(accounts=>{
      this.accounts=accounts
    });
  }

  toggleAddDialog(): void {
    this.showAddDialog = !this.showAddDialog;
  }
  editAccounts(account: Account): void {
    this.account=account;
    this.toggleAddDialog();
  }
  resetAccount(): void {
    this.account = {id:null,name:null,startingBalance:null,register:[],default:false};
  }
  addAccount(): void {
    this.resetAccount();
    this.toggleAddDialog();
  }
  saveAccount(): void {
    if (this.account.default) {
      //There can be only one...
      this.resetDefaultFlags();
    }
    if (this.account.id==null) {
      //add new
      this.accountService.addAccount(this.account);
    } else {
      //save
      this.accountService.saveAccount(this.account);
    }
    this.resetAccount();
    this.toggleAddDialog();
  }

  resetDefaultFlags(): void {
    for (let account of this.accounts) {
      if (this.account.id!=account.id &&  account.default) {
        account.default=false;
        this.accountService.saveAccount(account);
      }
    }
  }

  deleteAccount(): void {
    this.accountService.deleteAccount(this.account.id!);
    this.resetAccount();
    this.toggleAddDialog();    
  }


}
