export class Data {
    transactionTypes = [
        {
            key:"P",
            type:"Payment"
        },
        {
            key:"D",
            type:"Deposit"
        },
        {
            key:"T",
            type:"Transfer"
        }
    ];

    frequencyTypes = [
        {
            key:"O",
            type:"Once"
        },
        {
            key:"W",
            type:"Weekly"
        },
        {
            key:"M",
            type:"Monthly"
        },
        {
            key:"Y",
            type:"Yearly"
        }
    ]
    constructor(){}
}
