<div  style="background-color: white; position: fixed; top: 52px; width: 100%;border-bottom: 1px solid black;">

    <section class="section" style="margin-top: 0px;">
    <div class="container">
        <div class="columns">
            <div class="column">
                <div class="field">
                    <div class="control">
                        <div class="select is-primary">
                            <select name="type" id="type" [(ngModel)]="accountId" (change)="changeAccount()">
                                <option *ngFor="let account of accounts" [value]="account.id">{{account.name}}</option>
                            </select>
                        </div>
                        <button style="float: right;" class="button" (click)="toggleSearch()"><fa-icon [icon]="searchIcon"></fa-icon></button>
                        <button style="float: right;" class="button" (click)="toggleChart()"><fa-icon [icon]="lineChart"></fa-icon></button>
                    </div>
                </div> 
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <table class="table" *ngIf="!loading">
                    <tbody>
                        <tr (click)="scrollTo('today')">
                            <th>Balance Today</th>
                            <td>{{ today!.balance | currency }}</td>
                            <td>{{today?today.formattedDate!:null}}</td>
                        </tr>
                        <tr (click)="scrollTo('min')">
                            <th>Future Min</th>
                            <td>{{ min!.balance | currency}}</td>
                            <td>{{ min!.formattedDate! }}</td>
                        </tr>      
                        <tr (click)="scrollTo('max')">
                            <th>Future Max</th>
                            <td>{{ max!.balance! | currency }}</td>
                            <td>{{ max!.formattedDate! }}</td>
                        </tr>                                  
                    </tbody>
            </table>                
            </div>
        </div>
        <div class="columns is-mobile is-gapless" *ngIf="showSearch">
            <div class="column">
                <div style="width: 8em;display: inline-flex;margin-right: 4px;">
                    <dp-date-picker [(ngModel)]="startDate" displayDate="{{start.toLocaleDateString()}}" theme="dp-material" [config]="dpConfig" placeholder="Start Date" *ngIf="showSearch"></dp-date-picker>
                </div>
            </div>
            <div class="column">
                <div style="width: 8em;display: inline-flex;margin-right: 4px;">
                    <dp-date-picker [(ngModel)]="endDate" displayDate="{{end.toLocaleDateString()}}"  theme="dp-material" [config]="dpConfig" placeholder="End Date" *ngIf="showSearch"></dp-date-picker>
                </div>
            </div>
            <div class="column"><button (click)="refresh()" class="button" *ngIf="showSearch"><fa-icon [icon]="syncAlt"></fa-icon></button></div>
            
        </div>
    
    </div>
    </section>
    </div>
    <div [ngClass]="{'nosearch': !showSearch, 'search': showSearch}">
        <div *ngIf="loading" class="is-centered" style="width:100%;text-align: center;"><fa-icon [icon]="spinner" [spin]="true" size="2x"></fa-icon></div>
    <section>
        <table style="width:100%" class="table" *ngIf="overview.length>0 && !loading">
            <tbody>
                <tr *ngFor="let od of overview" [ngClass]="{'min':od.date==min!.date!,'max':od.date==max!.date!,'today':od.date==today!.date!}">
                    <td style="font-weight: bolder;">{{od.formattedDate!}}</td>
                    <td style="text-align: right;">
                        <span>{{od.balance | currency}}</span>
                        <br/>
                        <span style="font-size: smaller;">{{od.dayAmount | currency}}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
    </div>
    <app-overview-chart [chartData]="chartData" [chartLabels]="chartLabels" (closeDialog)="toggleChart()" *ngIf="showChart"></app-overview-chart>
    
