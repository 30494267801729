import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { TransactionComponent } from './transaction/transaction.component';
import { OverviewComponent } from './overview/overview.component';
import { AuthGuard } from './auth-guard.service';
import { RegisterComponent } from './register/register.component';
import { EstimatesComponent } from './estimates/estimates.component';
import { AccountsComponent } from './accounts/accounts.component';

const routes: Routes = [
  {path: 'transaction', component: TransactionComponent, canActivate:[AuthGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'overview', component: OverviewComponent, canActivate:[AuthGuard]},
  {path: 'register', component: RegisterComponent, canActivate:[AuthGuard]},
  {path: 'estimates', component: EstimatesComponent, canActivate:[AuthGuard]},
  {path: 'accounts', component: AccountsComponent, canActivate:[AuthGuard]},
  {path: '', redirectTo: '/transaction', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
