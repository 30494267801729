import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Estimate } from '../estimate';
import { Data } from '../data';
import { AccountService } from '../account-service';
import { Account } from '../account';
import { EstimateService } from '../estimate.service';


@Component({
  selector: 'app-estimate-detail',
  templateUrl: './estimate-detail.component.html',
  styleUrls: ['./estimate-detail.component.css']
})
export class EstimateDetailComponent implements OnInit {
  @Output() closeDialog = new EventEmitter();
  data: any;
  accounts: Account[]=[];
  dpConfig= {format:"M/D/YYYY"};
  startDate: any;
  endDate: any;
  frequencyDate: any;
  daysOfMonth: string = "";
  errorMessage: string | null = null;
  lastTransDate: any;

  dayOfWeek = [
    {code:"S", selected: false},
    {code:"M", selected: false},
    {code:"T", selected: false},
    {code:"W", selected: false},
    {code:"R", selected: false},
    {code:"F", selected: false},
    {code:"S", selected: false}
  ]

  monthOfYear = [
    {code:"Jan", selected:false},
    {code:"Feb", selected:false},
    {code:"Mar", selected:false},
    {code:"Apr", selected:false},
    {code:"May", selected:false},
    {code:"Jun", selected:false},
    {code:"Jul", selected:false},
    {code:"Aug", selected:false},
    {code:"Sep", selected:false},
    {code:"Oct", selected:false},
    {code:"Nov", selected:false},
    {code:"Dec", selected:false},
  ]

  @Input() estimate!: Estimate;
  constructor(private accountService: AccountService, private estimateService:EstimateService) {
    this.data=new Data();
    this.accountService.getAccounts().subscribe(accounts=>{
      this.accounts=accounts
      if (this.estimate.accountId==null){
        let def: Account = accounts.reduce(
          (def, current)=>{
            if (current.default) {
              return current;
            }
            return def;
          }
        );
        if (def!=null) {
          this.estimate.accountId=def.id;

        }
      }
    });
    
   }

  ngOnInit(): void {
    this.startDate = this.estimate.startDate?new Date(this.estimate.startDate).toLocaleDateString():null;
    this.endDate = this.estimate.endDate?new Date(this.estimate.endDate).toLocaleDateString():null;
    this.lastTransDate = this.estimate.lastTransDate?new Date(this.estimate.lastTransDate).toLocaleDateString():null;
    this.frequencyDate = this.estimate.frequency.date?new Date(this.estimate.frequency.date).toLocaleDateString():null;

    if (this.estimate.frequency.mode==="M") {
      if (this.estimate.frequency.dayOfMonth!=null) {
        this.daysOfMonth=this.estimate.frequency.dayOfMonth.join(",");
      }
      if (this.estimate.frequency.monthOfYear!=null) {
        this.estimate.frequency.monthOfYear.forEach(m=>{
          this.monthOfYear[m].selected=true;
        })
      }
    }

    if (this.estimate.frequency.mode==="W" && this.estimate.frequency.dayOfWeek!=null) {
      this.estimate.frequency.dayOfWeek.forEach(d=>this.dayOfWeek[d].selected=true);
    }

  }

  saveEstimate(): void {
    if (this.estimate.name==null) {
      this.errorMessage="Name missing";
      return;
    }
    if (this.estimate.amount==null || this.estimate.amount==0.0 || isNaN(this.estimate.amount)) {
      this.errorMessage="Amount missing or incorrect";
      return;
    }
    if (this.estimate.accountId==null) {
      this.errorMessage="Account missing";
      return;
    }
    if (this.startDate==null) {
      this.errorMessage="Start date missing";
      return;
    }
    if (this.estimate.frequency==null || this.estimate.frequency.mode==null){
      this.errorMessage="Choose a frequency";
      return;
    }

    //Populate the dates
    this.estimate.startDate=new Date(this.startDate).getTime();
    if (this.endDate!=null) {
      this.estimate.endDate=new Date(this.endDate).getTime();
    }
    if (this.lastTransDate) {
      this.estimate.lastTransDate=new Date(this.lastTransDate).getTime();
    } else {
      this.estimate.lastTransDate=null;
    }


    if (this.estimate.frequency.mode==="W") {
      let selected = this.dayOfWeek.map((day,idx)=>{
        if (day.selected) {
          return idx;
        }
        return -1;
      }).filter(idx=>idx>-1);

      if (selected.length<1) {
        this.errorMessage="Select at least one day of the week";
        return;
      }
      this.estimate.frequency.dayOfWeek=selected;
    }

    if (this.estimate.frequency.mode==="M") {
      if (this.daysOfMonth==null) {
        this.errorMessage="Enter at least one day of the month";
        return;
      }

      //@ts-ignore
      let days = this.daysOfMonth.split(",").map(ms=>ms.trim()).filter(m=>!isNaN(m)).map(ms=>parseInt(ms)).filter(m=>m<=31);
      if (days.length<1){
        this.errorMessage="Days of month should be a comma separated list of numbers";
        return;
      }

      this.estimate.frequency.dayOfMonth=days;

      let selected = this.monthOfYear.map((month,idx)=>{
        if (month.selected) {
          return idx;
        }
        return -1;
      }).filter(month=>month>-1);

      if (selected.length<1) {
        this.errorMessage="Select at least one month";
        return;
      }
      this.estimate.frequency.monthOfYear=selected;
    }

    if (this.estimate.id!=null) {
      this.estimateService.saveEstimate(this.estimate);
    } else {
      this.estimateService.addEstimate(this.estimate);
    }
    this.closeDialog.emit();
  }
  deleteEstimate(): void {
    this.estimateService.deleteEstimate(this.estimate.id!);
    this.close();
  }
  close(): void {
    this.closeDialog.emit();
  }
  
  clearError(): void {
    this.errorMessage=null;
  }

  selectAllMonths(): boolean {
    this.monthOfYear.forEach(m=>m.selected=true);
    return false;
  }
}
