import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { EventEmitter, Input } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { EstimateService } from '../estimate.service';
import { RegisterEntry } from '../register-entry';
import { Transaction } from '../transaction';
import { TransactionService } from '../transaction-service';
@Component({
  selector: 'app-register-detail',
  templateUrl: './register-detail.component.html',
  styleUrls: ['./register-detail.component.css']
})
export class RegisterDetailComponent implements OnInit {
  @Output() closeDialog = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Input() entry!: RegisterEntry | null;
  entryDate!: string;
  cleared: boolean=false;
  dpConfig= {format:"M/D/YYYY"};
  amt: number = 0.0;
  originalDate: number | null=null;
  constructor(private transactionService: TransactionService, private estimateService: EstimateService) { 
    
  }

  ngOnInit(): void {
    this.cleared=this.entry!.state==="C";
    this.entryDate=new Date(this.entry!.date!).toLocaleDateString();
    this.amt = this.entry!.amount!<0?-1.0 * this.entry!.amount!:this.entry!.amount!;
    this.originalDate=this.entry!.date;
  }
  close(): void {
    this.closeDialog.emit();
  }

  saveEntry(): void {
    this.entry!.date=new Date(this.entryDate).getTime();
    this.entry!.formattedDate=this.entryDate;
    this.entry!.state=this.cleared?"C":"P";
    this.entry!.amount=this.entry!.amount!<0?-1.0*this.amt:this.amt;
    if (this.entry!.originalItem) {
      if (this.entry!.type==="T") { //Transaction
        //@ts-ignore
        let trans: Transaction = {...this.entry!.originalItem};
        trans.amount = this.amt;
        trans.payee = this.entry!.description;
        trans.date=this.entry!.date;
        if (this.cleared) {
          trans.cleared=true;
        }
        this.transactionService.updateTransaction(trans.id!, trans);
      } else {
        //Create a transaction and set the last trans date
        let type = null;
        if (this.entry!.originalItem!.type==="T") {
          type=this.entry!.accountId==this.entry!.originalItem!.toAccountId?"D":"P";
        } else {
          type=this.entry!.originalItem!.type;
        }
        let trans: Transaction = {
           id: null,
           date: this.entry!.date,
           amount: Math.abs(this.entry!.amount),
           payee: this.entry!.description,
           type: type,
           cleared: this.cleared,
           accountId: this.entry!.accountId
        }
        this.transactionService.addTransaction(trans);
        if (this.entry!.originalItem!.type=="T") {
          let otherSideId=null;
          if (type==="D"){
            //transfer out account
            otherSideId=this.entry!.originalItem!.accountId;
          } else {
            //transfer in account
            otherSideId=this.entry!.originalItem!.toAccountId;
          }
          let otherTrans: Transaction={...trans};
          otherTrans.accountId=otherSideId;
          otherTrans.type=type==="D"?"P":"D";
          this.transactionService.addTransaction(otherTrans);
        }
  
        this.estimateService.updateEstimate(this.entry!.id!,{lastTransDate:this.originalDate});
        this.entry!.type="T"; //She's a transaction now
      }
    } 
    this.save.emit(this.entry);
  }

  deleteEntry(): void {
    if (this.entry!.type==="T") { //Transaction
      this.transactionService.deleteTransaction(this.entry!.id!);
    } else {
      //lastTransDate
      this.estimateService.updateEstimate(this.entry!.id!,{lastTransDate:this.entry!.date!});
    }
    this.entry!.date=this.originalDate;
    this.delete.emit(this.entry);
    this.closeDialog.emit();
  }
}
