import { Injectable, ɵConsole } from '@angular/core';
import { Estimate } from './estimate';
import { EstimateDetailComponent } from './estimate-detail/estimate-detail.component';
import { EstimateService } from './estimate.service';
import { RegisterEntry } from './register-entry';
import { Transaction } from './transaction';
import { TransactionService } from './transaction-service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private transactionService: TransactionService, private estimateService: EstimateService) { }


  convertTransactionToRegisterEntry(transaction: Transaction, accountId: string): RegisterEntry{

    return {
      id: transaction.id,
      accountId: accountId,
      description: transaction.payee,
      type: "T",
      date: transaction.date,
      state: transaction.cleared?"C":"P",
      amount: this.getRegisterAmountForTransaction(transaction,accountId),
      formattedDate: new Date(transaction.date!).toLocaleDateString(),
      originalItem: transaction
    }
  }

  getRegisterAmountForTransaction(transaction: Transaction, accountId: string): number {
    if (transaction.type==="P") return -1.0*transaction.amount!; //Paymens negative
    if (transaction.type==="D") return transaction.amount!; //Deposit positive

    if (transaction.type==="T" && transaction.accountId==accountId) { //Transfer from
      return -1.0 * transaction.amount!;
    }

    return transaction.amount!; //Transfer to and any other scenario
  }
  getRegisterAmountForEstimate(estimate: Estimate, accountId: string): number {
    if (estimate.type==="P") return -1.0*estimate.amount!; //Paymens negative
    if (estimate.type==="D") return estimate.amount!; //Deposit positive

    if (estimate.type==="T" && estimate.toAccountId==accountId) { //Transfer from

      return Math.abs(estimate.amount!);
    }

    return estimate.amount!; //Transfer to and any other scenario
  }
  convertEstimateToRegistryEntries(estimate: Estimate, startDate: number | null, endDate: number, accountId: string): RegisterEntry[] {
    if (estimate.frequency==null || estimate.frequency.mode==null) {
      return [];
    }
    
    const targetDate = estimate.lastTransDate==null?estimate.startDate:this.getFutureTime(new Date(estimate.lastTransDate),1); //Start counting day after last trans
    
    if (targetDate==null) {
      return [];
    }
    if (estimate.frequency.mode==="O" && this.dateInRange(targetDate!,startDate,endDate) && estimate.lastTransDate==null) {
      return [this.convertEstimateToRegisterEntry(estimate, estimate.startDate!, accountId)];
    }

    if (estimate.frequency.mode==="W") {
      if (estimate.frequency.dayOfWeek==null) {
        return [];
      }
      if (estimate.frequency.everyNWeeks==null) {
        estimate.frequency.everyNWeeks=1;
      }

      let entries: RegisterEntry[] = [];
      [...estimate.frequency.dayOfWeek].sort().forEach(di =>{
        let start = this.findNextDayOfWeek(estimate,di);
        console.log(new Date(start));
        let weekDate = new Date(start);
        const increment = estimate.frequency.everyNWeeks! * 7;
        while (start<=endDate) {
          entries.push(this.convertEstimateToRegisterEntry(estimate,weekDate.getTime(),accountId));

          weekDate.setDate(weekDate.getDate()+increment);
          start = weekDate.getTime();
        }

      })
      return entries;
    }

    if (estimate.frequency.mode==="M") {
      if (estimate.frequency.dayOfMonth==null || estimate.frequency.monthOfYear==null) {
        return [];
      }

      let entries: RegisterEntry[] = [];

      [...estimate.frequency.monthOfYear].forEach(m=>{
        [...estimate.frequency.dayOfMonth!].forEach(day=>{
          let start = this.findNextDayOfMonth(estimate,day,m);
          let monthDate = new Date(start);
          while (start<=endDate) {
            entries.push(this.convertEstimateToRegisterEntry(estimate,monthDate.getTime(),accountId));
            monthDate.setFullYear(monthDate.getFullYear()+1);
            start = monthDate.getTime();
          }
        })
      })

      return entries;
    }

    return [];
  }

  findNextDayOfMonth(estimate: Estimate, day: number, month: number): number {
    let startDate = new Date(estimate.startDate!);
    let year = startDate.getFullYear();
    let startMonth = startDate.getMonth();
    let startDay = startDate.getDate();
     if (estimate.lastTransDate!=null) {
      let transDate =  new Date(estimate.lastTransDate);
      let candidate = new Date(transDate.getFullYear(),month,day);
      if (candidate.getTime()<=transDate.getTime()) {
        candidate.setFullYear(candidate.getFullYear()+1);
      }
      return candidate.getTime();
    } else {
      if (startMonth>month || startDay>day) { //Already passed this month this year or this day of the month
        year++;
      }
      return new Date(year,month,day).getTime();
    }

    
  }
  findNextDayOfWeek(estimate: Estimate, day: number): number {
    if (estimate.lastTransDate==null) {
      //Never been executed. Find first day of the week
      let startDate = new Date(estimate.startDate!);
      if (startDate.getDay()===day) return startDate.getTime(); //Start date is the right day of the week
      let future = new Date(startDate.getTime());
      for (let i=1;i<7;i++) { //Day at a time until we find the first one
        future = new Date(startDate.getTime());
        future.setDate(startDate.getDate()+i);
        if (future.getDay()==day){
          break;
        }
      }
      return future.getTime();
    } else {
      //Find next day of week; need to account for last run on Sunday, day of week changed to Saturday
      const increment = estimate.frequency.everyNWeeks! * 7;
      let transDate = new Date(estimate.lastTransDate);
      
      let lastDay = transDate.getDay();

      //Find the same day in the week of the last execution
      let firstOfWeek = new Date(estimate.lastTransDate);
      firstOfWeek.setDate(firstOfWeek.getDate()-lastDay);

      for (let i=0;i<7;i++) {
        let refDate = new Date(firstOfWeek.getTime());
        refDate.setDate(firstOfWeek.getDate()+i);
        if (refDate.getDay()==day) {
          if (lastDay < day) { //Still in the same week. e.g. last trans on monday, this guy on friday
            return refDate.getTime();
          } else {
            //Add the increment
            return refDate.setDate(refDate.getDate()+increment);
          }
        }
      }
      


      //Last ditch, just return increment from last trans date
      let lastDitch = new Date(estimate.lastTransDate);
      lastDitch.setDate(transDate.getDate()+increment);
      return lastDitch.getTime();
    }
    
  }

  getFutureTime(refDate: Date, days: number): number {
    var future = new Date(); 
    return future.setDate(refDate.getDate()+days);
  }

  convertEstimateToRegisterEntry(estimate: Estimate, date: number, accountId: string): RegisterEntry{

    return {
      id: estimate.id,
      accountId: accountId,
      description: estimate.name,
      type: "E",
      date: date,
      state: "P",
      amount: this.getRegisterAmountForEstimate(estimate,accountId),
      formattedDate: new Date(date).toLocaleDateString(),
      originalItem:estimate
    }
  }
  
  dateInRange(targetDate: number, startDate: number | null, endDate: number): boolean {
    return (startDate==null || targetDate>=startDate) && targetDate<=endDate
  }
}
