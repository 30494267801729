import { Component, OnInit } from '@angular/core';
import { faSyncAlt, faSearch, faCheckSquare, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare as outlineSquare} from '@fortawesome/free-regular-svg-icons';
import { Account } from '../account';
import { AccountService } from '../account-service';
import { Estimate } from '../estimate';
import { EstimateService } from '../estimate.service';
import { RegisterEntry } from '../register-entry';
import { RegisterService } from '../register.service';
import { Transaction } from '../transaction';
import { TransactionService } from '../transaction-service';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  syncAlt = faSyncAlt;
  searchIcon=faSearch;
  clearIcon=faCheckSquare;
  pendingIcon=outlineSquare;
  spinner=faSpinner;
  showSearch: boolean = false;
  startDate: string | null=null;
  endDate: string | null=null;
  dpConfig= {format:"M/D/YYYY"};
  accounts: Account[]=[];
  account!: Account | null;
  accountId: string | null=null;
  transactions: Transaction[]=[];
  estimates: Estimate[]=[];
  register: RegisterEntry[]=[];
  query: string = "";
  hideCleared: boolean = true;
  wip: RegisterEntry[]=[];
  registryEntry: RegisterEntry | null=null;
  showDetails: boolean = false;
  loading: boolean = false;
  constructor(private accountService: AccountService, private transactionService: TransactionService, private estimateService: EstimateService, private registerService: RegisterService) { }

  ngOnInit(): void {
    this.endDate=new Date(this.getFutureTime(30)).toLocaleDateString();
    this.accountService.getAccounts().subscribe(accounts=>{
      this.accounts=accounts

        let def: Account = accounts.reduce(
          (def, current)=>{
            if (current.default) {
              return current;
            }
            return def;
          }
        );
        this.account=def;
        this.accountId=this.account.id;
        this.retrieveRegister();

    });

  }

  scrollToTop(): void {
    window.scrollTo(0,0)

  }
  changeAccount(): void {
    if (this.accountId != null) {
      let acct = this.accounts.find(a=>a.id==this.accountId);
      if (acct) {
        this.account=acct;
      }
      this.retrieveRegister();
    }
  }

  getFutureTime(days: number): number {
    var today = new Date(); 
    var future = new Date(); 
    return future.setDate(today.getDate()+days);
  }
  retrieveRegister(): void {
    if (this.account==null) return;

    this.loading=true;
    this.transactionService.getTransactionsForAccount(this.account.id!).pipe(first()).subscribe(transactions=>{
      this.transactions=transactions;
      this.retrieveEstimates();
    });
    
  }

  refresh(): void {
    this.register=[];
    this.retrieveRegister();
  }


  generateRegister(): void {
    let start: number | null = this.startDate==null?null:new Date(this.startDate).getTime();
    let end: number | null = this.endDate==null?this.getFutureTime(30):new Date(this.endDate).getTime();
    this.wip=[];
    this.transactions.forEach(trans => {
      this.wip.push(this.registerService.convertTransactionToRegisterEntry(trans, this.account!.id!));
    })
    this.estimates.forEach(estimate =>{
      let er = this.registerService.convertEstimateToRegistryEntries(estimate, start, end!, this.account!.id!);
      er.forEach(e=>this.wip.push(e));
    })
    
    this.wip.sort((a,b)=>a.date!-b.date!);

    if (start!=null) {
      this.wip = this.wip.filter(item=>item.date!>=start!);
    } else {
      let ci = this.wip.findIndex(item=>item.state!="C");
      if (ci>-1) {
        this.wip = this.wip.slice(ci);
      }
    }
    
    this.register=[...this.wip];
    this.loading=false;

  }
  retrieveEstimates(): void {
    if (this.account==null) return;
    this.estimateService.getEstimatesForAccount(this.account.id!).pipe(first()).subscribe(estimates=>{
      this.estimates=estimates.filter((estimate)=>{
        if (estimate==null) return false;

        let startTime = this.startDate!=null?new Date(this.startDate).getTime():null;
        let endTime = this.endDate!=null?new Date(this.endDate).getTime():null;
    
        if (startTime!=null && estimate.endDate!=null && estimate.endDate<startTime) {
          return false; //estimate end date is before start date
        }
        if (endTime!=null && estimate.startDate!=null && estimate.startDate>endTime) {
          return false; //estimate start date is after end date
        }
    
        return true; //not before and not after      
      });
      this.generateRegister();
    });
  }


  toggleSearch(): void {
    this.showSearch=!this.showSearch;
  }

  changeState(entry: RegisterEntry): void {
    if (entry.type==="T") {
      this.transactionService.updateTransaction(entry.id!, {cleared: "P"===entry.state});
      entry.state="P"===entry.state?"C":"P";
    } else {
      //Update the last trans date
      //lastTransDate
      this.estimateService.updateEstimate(entry.id!,{lastTransDate:entry.date});
        //Create a transaction for the estimate
        let trans: Transaction = {
          id: null,
          date: entry.date,
          amount: Math.abs(entry.amount!),
          payee: entry.description,
          type: entry.amount!<0?"P":"D",
          accountId: this.account!.id,
          cleared: true
        }
      
      if (entry.originalItem.type==="T") {
        let fromAcctId = entry.originalItem.accountId;
        let toAcctId = entry.originalItem.toAccountId;

        trans.accountId=fromAcctId;
        trans.type="P";
        let to = {...trans};
        to.accountId=toAcctId;
        to.type="D";
        if (this.accountId==fromAcctId) {
          this.transactionService.addTransaction(to).subscribe();
          this.transactionService.addTransaction(trans).subscribe(e=>{
            entry.id=e.key;
            entry.type="T";
            entry.state="C"
          });
        } else {
          this.transactionService.addTransaction(trans).subscribe();
          this.transactionService.addTransaction(to).subscribe(e=>{
            entry.id=e.key;
            entry.type="T";
            entry.state="C"
          });
        }

        
      } else {

        this.transactionService.addTransaction(trans).subscribe(e=>{
          entry.id=e.key;
          entry.type="T";
          entry.state="C"
        });
      }
    }
  }

  toggleDetails(): void {
    this.showDetails = !this.showDetails;
  }

  deleteEntry(entry: RegisterEntry): void {
    this.register = this.register.filter(item=>
      {
        if (entry.type==="E"){
          if (item.id==entry.id && item.date==entry.date) {
            return false;
          } else {
            return true;
          }
        } else {
          return item.id!=entry.id;
        }
      });
  }

  editEntry(entry: RegisterEntry): void {
    this.registryEntry=entry;
    this.showDetails=true;
  }

  updateEntry(updated: RegisterEntry): void {
    this.registryEntry={...updated};
    this.register.sort((a,b)=>a.date!-b.date!);
    this.toggleDetails();
  }
}
