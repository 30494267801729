<section class="section" style="margin-top: 0px;">
    <div class="container">
<div style="width: 100%; text-align: center; margin-top: 100px;">
    <input [(ngModel)]="username" placeholder="Username"/>
    <input [(ngModel)]="password" placeholder="Password" type="password"/><br/>
<button *ngIf="!showSpinner" (click)='login()' class="button is-primary">Login</button>
<app-load-spinner *ngIf="showSpinner"></app-load-spinner>
</div>
</div>
</section>

