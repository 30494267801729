<div  style="background-color: white; position: fixed; top: 52px; width: 100%;border-bottom: 1px solid black;">

<section class="section" style="margin-top: 0px;">
<div class="container">
    <div class="columns">
        <div class="column">
            <div class="field">
                <div class="control">
                    <div class="select is-primary">
                        <select name="type" id="type" [(ngModel)]="accountId" (change)="changeAccount()">
                            <option *ngFor="let account of accounts" [value]="account.id">{{account.name}}</option>
                        </select>
                    </div>
                    <button style="float: right;" class="button" (click)="toggleSearch()"><fa-icon [icon]="searchIcon"></fa-icon></button>
                </div>
            </div> 
        </div>
    </div>
    <div class="columns is-mobile is-gapless">
        <div class="column"><input type="text" class="input is-primary" placeholder="Search" [(ngModel)]="query" (keyup)="scrollToTop()"/> 
            <div style="margin-top: 1em"><label class="checkbox"><input type="checkbox" [(ngModel)]="hideCleared"/> Hide cleared</label></div>
        </div>
    </div>    
    <div class="columns is-mobile is-gapless" *ngIf="showSearch">
        <div class="column">
            <div style="width: 8em;display: inline-flex;margin-right: 4px;">
                <dp-date-picker [(ngModel)]="startDate"  theme="dp-material" [config]="dpConfig" placeholder="Start Date" *ngIf="showSearch"></dp-date-picker>
            </div>
        </div>
        <div class="column">
            <div style="width: 8em;display: inline-flex;margin-right: 4px;">
                <dp-date-picker [(ngModel)]="endDate"  theme="dp-material" [config]="dpConfig" placeholder="End Date" *ngIf="showSearch"></dp-date-picker>
            </div>
        </div>
        <div class="column"><button (click)="refresh()" class="button" *ngIf="showSearch"><fa-icon [icon]="syncAlt"></fa-icon></button></div>
        
    </div>

</div>
</section>
</div>
<div [ngClass]="{'nosearch': !showSearch, 'search': showSearch}" #registerData>
    <div *ngIf="loading" class="is-centered" style="width:100%;text-align: center;"><fa-icon [icon]="spinner" [spin]="true" size="2x"></fa-icon></div>
<section>
    <table style="width:100%" class="table" *ngIf="register.length>0 && !loading">
        <tbody>
            <tr *ngFor="let item of register | regsearch:query | cleared:hideCleared" [ngClass]="{'pending':item.state==='P'&&item.type==='T','estimate':item.type==='E'}">
                <td (click)="editEntry(item)">
                    <div style="font-size: larger;">{{item.description}} <span *ngIf="item.type==='E'"> - estimate</span></div>
                    <div>{{item.formattedDate}}</div>
                </td>
                <td style="text-align: center;">
                    <fa-icon *ngIf="item.state==='C'" [icon]="clearIcon" style="font-size: x-large;" (click)="changeState(item)"></fa-icon>
                    <fa-icon *ngIf="item.state==='P'" [icon]="pendingIcon" style="font-size: x-large;" (click)="changeState(item)"></fa-icon>
                </td>
                <td><div  style="font-size: larger;text-align: right;">{{item.amount | currency}}</div></td>
            </tr>
        </tbody>
    </table>


</section>
</div>
<app-register-detail [entry]="registryEntry" *ngIf="showDetails" (save)="updateEntry($event)" (closeDialog)="toggleDetails()" (delete)="deleteEntry($event)"></app-register-detail>