import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { LoginComponent } from './login/login.component';
import { TransactionComponent } from './transaction/transaction.component';
import { LoadSpinnerComponent } from './load-spinner/load-spinner.component';
import { OverviewComponent } from './overview/overview.component';
import { RegisterComponent } from './register/register.component';
import { AccountsComponent } from './accounts/accounts.component';
import { EstimatesComponent } from './estimates/estimates.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import { FormsModule } from '@angular/forms';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EstimateDetailComponent } from './estimate-detail/estimate-detail.component';
import { RegsearchPipe } from './regsearch.pipe';
import { ClearedPipe } from './cleared.pipe';
import { RegisterDetailComponent } from './register-detail/register-detail.component';
import { ChartsModule } from 'ng2-charts';
import { OverviewChartComponent } from './overview-chart/overview-chart.component';
import { VoiceTransactionComponent } from './voice-transaction/voice-transaction.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TransactionComponent,
    LoadSpinnerComponent,
    OverviewComponent,
    RegisterComponent,
    AccountsComponent,
    EstimatesComponent,
    NumbersOnlyDirective,
    EstimateDetailComponent,
    RegsearchPipe,
    ClearedPipe,
    RegisterDetailComponent,
    OverviewChartComponent,
    VoiceTransactionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    DpDatePickerModule,
    FormsModule,
    FontAwesomeModule,
    ChartsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
