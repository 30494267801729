import { Component, OnInit } from '@angular/core';
import { AccountService } from '../account-service';
import { faSyncAlt, faSearch, faSpinner, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { OverviewDay } from '../overview-day';
import { RegisterService } from '../register.service';
import { TransactionService } from '../transaction-service';
import { Transaction } from '../transaction';
import { first } from 'rxjs/operators';
import { EstimateService } from '../estimate.service';
import { Estimate } from '../estimate';
import { RegisterEntry } from '../register-entry';
import { Account } from '../account';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  dpConfig= {format:"M/D/YYYY"};
  syncAlt = faSyncAlt;
  lineChart = faChartLine;
  searchIcon=faSearch;
  spinner=faSpinner;
  accounts: Account[]=[];
  account!: Account;
  accountId: string | null=null;
  startDate: string;
  endDate: string;
  start: Date;
  end: Date;
  showSearch: boolean = false;
  overview: OverviewDay[]=[];
  loading: boolean = true;
  transactions: Transaction[]=[];
  estimates: Estimate[]=[];
  register: RegisterEntry[]=[];
  min: OverviewDay | null = null;
  max: OverviewDay | null = null;
  today: OverviewDay | null = null;
  chartData: ChartDataSets[] = [];
  chartLabels: Label[] = [];
  showChart: boolean = false;
  //dayAmount
  constructor(private accountService: AccountService, private registerService: RegisterService, private transactionService:TransactionService, private estimateService: EstimateService) { 
    this.start = new Date();
    this.startDate=this.start.toLocaleDateString();
    this.end = new Date(this.startDate);
    this.end.setDate(this.start.getDate()+90);
    this.endDate=this.end.toLocaleDateString();
  }

  ngOnInit(): void {
    this.accountService.getAccounts().subscribe(accounts=>{
      this.accounts=accounts

        let def: Account = accounts.reduce(
          (def, current)=>{
            if (current.default) {
              return current;
            }
            return def;
          }
        );
        this.account=def;
        this.accountId=this.account.id;
        this.retrieveRegister();

    });    
  }

  toggleChart(): void {
    this.showChart=!this.showChart;
  }
  populateChartData(): void {
    let balances: number[]=this.overview.map(od=>od.balance);
    this.chartData=[{data:balances}];
    this.chartLabels=this.overview.map(od=>od.formattedDate!);
  }
  retrieveRegister(): void {
    if (this.account==null) return;

    this.loading=true;
    this.transactionService.getTransactionsForAccount(this.account.id!).pipe(first()).subscribe(transactions=>{
      this.transactions=transactions;
      this.retrieveEstimates();
    });
    
  }
  retrieveEstimates(): void {
    if (this.account==null) return;
    this.estimateService.getEstimatesForAccount(this.account.id!).pipe(first()).subscribe(estimates=>{
      this.estimates=estimates.filter((estimate)=>{
        if (estimate==null) return false;

        let startTime = this.startDate!=null?new Date(this.startDate).getTime():null;
        let endTime = this.endDate!=null?new Date(this.endDate).getTime():null;
    
        if (startTime!=null && estimate.endDate!=null && estimate.endDate<startTime) {
          return false; //estimate end date is before start date
        }
        if (endTime!=null && estimate.startDate!=null && estimate.startDate>endTime) {
          return false; //estimate start date is after end date
        }
    
        return true; //not before and not after      
      });
      this.generateRegister();
    });
  } 
  generateRegister(): void {
    let start: number | null = this.startDate==null?null:new Date(this.startDate).getTime();
    let end: number | null = this.endDate==null?this.getFutureTime(new Date(start!),90):new Date(this.endDate).getTime();
    this.register=[];
    this.transactions.forEach(trans => {
      this.register.push(this.registerService.convertTransactionToRegisterEntry(trans, this.account!.id!));
    })
    this.estimates.forEach(estimate =>{
      let er = this.registerService.convertEstimateToRegistryEntries(estimate, start, end!, this.account!.id!);
      er.forEach(e=>this.register.push(e));
    })
    
    this.register.sort((a,b)=>a.date!-b.date!);

    let amtByDay={};
    this.register.forEach(entry=>{
      let date = new Date(entry.date!).toLocaleDateString();
      if (!amtByDay.hasOwnProperty(date)){
        //@ts-ignore
        amtByDay[date]=entry.amount;
      } else {
        //@ts-ignore
        amtByDay[date]=amtByDay[date]+entry.amount;
      }
    })


    let balance = this.register.filter(entry=>entry.date!<start!).reduce((total,amt)=>total+amt.amount!,this.account.startingBalance!);
    let workingDate: number = start!;
    while (workingDate<=end) {
      let theDate: Date = new Date(workingDate);
      let dateString: string = theDate.toLocaleDateString();
      //@ts-ignore
      let dayAmount: number = amtByDay.hasOwnProperty(dateString)?amtByDay[dateString]:0.0;
      balance += dayAmount;
      let od: OverviewDay = {
        date: workingDate,
        formattedDate: dateString,
        balance: balance,
        dayAmount: dayAmount
      }

      this.overview.push(od);
      let nextDay: Date = new Date(workingDate);
      nextDay.setDate(theDate.getDate()+1);
      workingDate=nextDay.getTime();
    }
    console.log(this.overview);

    //let min: number = this.overview.map(od=>od.balance).reduce((min, bal)=>bal<min?bal:min,999999);
    this.min = this.overview.reduce((md,curr)=>curr.balance<md.balance?curr:md,this.overview[0]);
    this.max = this.overview.reduce((md,curr)=>curr.balance>md.balance?curr:md,this.overview[0]);
    this.today = this.overview[0];
    this.populateChartData();
    this.loading=false;

  }   
  toggleSearch(): void {
    this.showSearch=!this.showSearch;
  }
  changeAccount(): void {
    if (this.accountId != null) {
      let acct = this.accounts.find(a=>a.id==this.accountId);
      if (acct) {
        this.account=acct;
      }
    }
  }  
  refresh(): void {
    if (!this.startDate) {
      this.start=new Date();
      this.startDate=this.start.toLocaleDateString();
    } else {
      this.start = new Date(this.startDate);
    }
    if (!this.endDate) {
      this.end=new Date(this.getFutureTime(this.start,90));
      this.endDate=this.end.toLocaleDateString();
    } else {
      this.end = new Date(this.endDate);
    }
    this.retrieveRegister();
  }

  getFutureTime(refDate: Date, days: number): number {
    var future = new Date(refDate); 
    return future.setDate(refDate.getDate()+days);
  }  
  scrollTo(where: string): void {
    window.document.getElementsByClassName(where)[0].scrollIntoView({block:"center"})
  }
}
