import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cleared'
})
export class ClearedPipe implements PipeTransform {
  transform(items: any[], filter: Object): any {
    //@ts-ignore
    let hide = filter;
    if (!hide) return items;
    //@ts-ignore
    return (items || []).filter((item: { state: string; })=>item.state!="C");
    console.log(filter);
    return items;
  }
}
