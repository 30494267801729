import { CanActivate, Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private auth: AngularFireAuth, private router: Router, private route: ActivatedRoute) { }

	canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
		return from(this.auth.authState).pipe(
			take(1),
			map((user: any)=>{
				//&& (user.uid=="bOf7KKKW6ogKPto2PDXnesaTWg52" || user.uid=="e0n9evmWMzea3wOFt5TV0oKVVdZ2")
				if (user ){
					return true;
				}
				console.log("nope")
				return false
			}),
			tap((authenticated: any) => {
				console.log(authenticated)
				if (!authenticated) {
					this.router.navigate(['/login'], {
						queryParams: {
							return: routerState.url
						}
					});
				}
			})
		);

	}
}
