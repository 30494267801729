import { Pipe, PipeTransform } from '@angular/core';
import { RegisterEntry } from './register-entry';

@Pipe({
  name: 'regsearch'
})
export class RegsearchPipe implements PipeTransform {

  transform(items: any[], filter: Object): any {
    //@ts-ignore
    let term: string = filter;
    if (!term) return items;
    return (items || []).filter((item: { description: string; amount:number; })=>
      new RegExp(term, 'gi').test(item.description) || (!isNaN(parseFloat(term)) && parseFloat(term)==Math.abs(item.amount))
    )
    return items;
  }

}
