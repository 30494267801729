<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Overview</p>
        <button class="delete" aria-label="close" (click)="close()"></button>
      </header>
      <section class="modal-card-body">
        <div>
            <canvas baseChart width="400" height="300"
              [datasets]="chartData"
              [labels]="chartLabels"
              [options]="lineChartOptions"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              [plugins]="lineChartPlugins">
            </canvas>
          </div>         
      </section>
    </div>
  </div>