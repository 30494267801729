
<div>
<nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a #burger role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" (click)="toggleNavbar()">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>
  <div class="navbar-menu" #navMenu>
    <div class="navbar-start">
      <a class="navbar-item" routerLink="transaction" (click)="toggleNavbar()">
        Add Transaction
      </a>
      <a class="navbar-item" routerLink="overview" (click)="toggleNavbar()">
        Overview
      </a>
      <a class="navbar-item" routerLink="register" (click)="toggleNavbar()">
        Register
      </a>
      <a class="navbar-item" routerLink="estimates" (click)="toggleNavbar()">
        Estimates
      </a>      
      <a class="navbar-item" routerLink="accounts" (click)="toggleNavbar()">
        Accounts
      </a>
      <a *ngIf="authService.isAuthenticated() | async" class="navbar-item" href="#" (click)="logout()">Logout</a>
    </div>
  </div>
</nav>
</div>
<router-outlet></router-outlet>


