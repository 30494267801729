import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  return = '';
  showSpinner = localStorage.getItem('showSpinner') === 'true' ? true : false;
  username="";
  password="";
  //1bEW1XAe6xb2y41eq22tVxZ8O1x1  - Jeni
  //bOf7KKKW6ogKPto2PDXnesaTWg52 - Nathan
  constructor(private authService: AuthService, private router: Router, private afAuth: AngularFireAuth, private route: ActivatedRoute) { 
    this.afAuth.authState.subscribe(auth => {
      localStorage.setItem('showSpinner', 'false');
      this.showSpinner=false;
      if (auth) {
         this.router.navigateByUrl(this.return);
      }
   });
  }

  ngOnInit(): void {
    // firebase.auth().getRedirectResult().then((res)=>{
    //   if (res && res.user) {
    //     this.router.navigate(['/transaction']);
    //   }
    // });
    this.route.queryParams
    .subscribe(params => this.return = params['return'] || '/transaction');
  }

  login(): void {
    localStorage.setItem("showSpinner", 'true');
    this.authService.login(this.username,this.password);
  }
}
