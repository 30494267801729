import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-overview-chart',
  templateUrl: './overview-chart.component.html',
  styleUrls: ['./overview-chart.component.css']
})
export class OverviewChartComponent implements OnInit {
  @Output() closeDialog = new EventEmitter();
  @Input() chartData!: ChartDataSets[];
  @Input() chartLabels!: Label[];
  lineChartLegend = false;
  lineChartType: ChartType= 'line';
  lineChartPlugins = [];
  lineChartOptions: ChartOptions = {
    responsive: true,
  };
  constructor() { }

  ngOnInit(): void {

  }

  close(): void {
    this.closeDialog.emit();
  }
}
