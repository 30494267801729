import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-voice-transaction',
  templateUrl: './voice-transaction.component.html',
  styleUrls: ['./voice-transaction.component.css']
})
export class VoiceTransactionComponent implements OnInit {
  @Output() closeDialog = new EventEmitter();
  @Output() saveVoice = new EventEmitter();
  cmd: string | null=null;
  constructor() { }

  ngOnInit(): void {
    window.document.getElementById("cmd")?.focus();
  }

  close(): void {
    this.closeDialog.emit();
  }

  translate(): void {
    if (this.cmd==null || this.cmd?.length==0) return;
    let type = this.extractType(this.cmd);
    let amount = this.extractAmount(this.cmd);
    let payee = this.extractPayee(this.cmd);
    let saveIndex = payee.toUpperCase().indexOf(" SAVE");
    if (saveIndex>-1){
      payee = payee.substr(0,saveIndex);
    }
    if (type!=null && amount!=null) {
      this.saveVoice.emit({type, amount, payee})
    }
  }

  extractType(command: string): string {
    if (command.toUpperCase().indexOf("DEPOSIT")>-1) {
      return "D";
    }
    return "P";
  }
  extractAmount(command: string): number | null {
    let match = command.match(/[\d\.]+/);
    if (!match) return null;

    let ext = match[0];

    //@ts-ignore
    if (!isNaN(ext)) {
      return parseFloat(ext);
    }

    return null;

  }

  extractPayee(command: string): string {
    let candidates=[
      " OF ",
      " FOR ",
      " AT ",
      " IN "
    ];

    for (let i=0;i<candidates.length;i++) {
      let idx = command.toUpperCase().indexOf(candidates[i]);
      if (idx>-1) {
        return command.substr(idx + candidates[i].length);
      }
    }
    return "Transaction on "+(new Date().toLocaleDateString());
  }

  listenForSave(): void {
    if (this.cmd && this.cmd.toUpperCase().indexOf(" SAVE")>-1) {
      this.translate();
    }
  }

}
