<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Register</p>
        <button class="delete" aria-label="close" (click)="close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
            <label class="label">Name</label>
            <div class="control">
                <input class="input is-primary" type="text" [(ngModel)]="entry!.description"/>
            </div>
        </div>
        <div class="field">
            <label class="label">Date</label>
            <div class="control">
                <dp-date-picker [(ngModel)]="entryDate"  theme="dp-material" [config]="dpConfig" placeholder="Date"></dp-date-picker>
            </div>
        </div>
        <div class="field">
            <label class="label">Amount</label>
            <div class="control">
                <input type="number" class="input is-primary number1" [(ngModel)]="amt"/>
            </div>
        </div>
        <div class="field">
            <label class="label">Cleared</label>
            <div class="control">
                <input type="checkbox" class="is-primary" [(ngModel)]="cleared"/>
            </div>
        </div>          
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="saveEntry()">Save</button>
        <button class="button is-danger" (click)="deleteEntry()">Delete</button>
        <button class="button" (click)="close()">Cancel</button>
      </footer>
    </div>
  </div>