import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { from, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Estimate } from './estimate';
import { Frequency } from './frequency';

@Injectable({
  providedIn: 'root'
})
export class EstimateService {

  estimateRef: AngularFireList<any>;
  
  constructor(private db: AngularFireDatabase) { 
    this.estimateRef=db.list("estimates");
  }

  getEstimates(): Observable<any[]> {
    return this.estimateRef.snapshotChanges().pipe(
      map((changes: any[]) => 
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
      )
    );
  }
  addEstimate(estimate: Estimate): Observable<any> {
    return from(this.estimateRef.push(this.stripId(estimate))).pipe(first());
  }

  saveEstimate(estimate: Estimate): void {
    let id = estimate.id;
    this.db.object(`estimates/${id}`).set(this.stripId(estimate));
  }
  
  deleteEstimate(id: string): void {
    this.db.object(`estimates/${id}`).remove();
  }  
  stripId(estimate: Estimate):any {
    let fbAcct: any={...estimate};
    delete fbAcct.id;
    return fbAcct;
  }
  getEstimatesForAccount(accountId: string): Observable<any[]> {
    //console.log(accountId);
    //, ref=>ref.orderByChild("accountId").equalTo(accountId)
    return this.db.list("/estimates").snapshotChanges().pipe(
      map((changes: any[]) => 
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
        .filter(e=>e.accountId===accountId || e.toAccountId===accountId)
        .map(e=>{
          if (e.type==="T"&&e.accountId===accountId){ //Transfer out
            e.amount = -1.0 * e.amount;
          }
          return e;
        })
      )
    );
  }  
  updateEstimate(id: string, updates: any): void {
    this.db.object(`/estimates/${id}`).update(updates);
  }
  generateEstimate(): Estimate {
    let frequency:Frequency = {
      mode: null
    }
    let estimate = {
      id: null,
      name: null,
      amount: null,
      startDate: null,
      endDate: null,
      lastTransDate: null,
      accountId: null,
      type: "P",
      frequency: {mode: null,everyNWeeks:1}
    }
    return estimate;
  }
}
