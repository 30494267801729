<section class="section">
    <div class="container">
        <h3 class="title" style="float:left">Add Transaction</h3>
        <button style="float: right;" class="button" (click)="toggleVoice()"><fa-icon [icon]="microphone"></fa-icon></button>
    </div>
</section>
<section class="section" style="padding-top: 16px;">
    <div class="container">
        <div class="field">
            <label class="label">Name</label>
            <div class="control">
                <input class="input is-primary" type="text" [(ngModel)]="transaction.payee"/>
            </div>
        </div>
        <div class="field">
            <label class="label">Amount</label>
            <div class="control">
                <input type="number" class="input is-primary number1" [(ngModel)]="transaction.amount"/>
            </div>
        </div> 
        <div class="field">
            <label class="label">Date</label>
            <div class="control">
                <dp-date-picker [(ngModel)]="inputDate"  theme="dp-material" [config]="dpConfig"></dp-date-picker>
            </div>
        </div>
        <div class="field">
            <label class="label">Type</label>
            <div class="control">
                <div class="select is-primary">
                    <select name="type" id="type" [(ngModel)]="transaction.type">
                        <option *ngFor="let type of data.transactionTypes" [value]="type.key">{{type.type}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="field">
            <label class="label">Account</label>
            <div class="control">
                <div class="select is-primary">
                    <select name="type" id="type" [(ngModel)]="transaction.accountId">
                        <option *ngFor="let account of accounts" [value]="account.id">{{account.name}}</option>
                    </select>
                </div>
            </div>
        </div>   
        <div class="field" *ngIf="transaction.type==='T'">
            <label class="label">To Account</label>
            <div class="control">
                <div class="select is-primary">
                    <select name="type" id="type" [(ngModel)]="transaction.toAccountId">
                        <option *ngFor="let account of accounts" [value]="account.id">{{account.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="field">
            <div class="control">
                <label class="checkbox">
                    <input type="checkbox" class="checkbox" [(ngModel)]="transaction.cleared">
                    <span class="checkbox">&nbsp;Cleared</span>
                  </label>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="box has-text-centered">
        <button class="button is-dark" [class.is-loading]="savingData" (click)="saveTransaction()">Save</button>
    </div>
    <div class="notification is-danger is-light" *ngIf="errorMessage!=null">
        <button class="delete" (click)="clearError()"></button>
        {{errorMessage}}
    </div>  
</section>
<app-voice-transaction (closeDialog)="toggleVoice()" *ngIf="showVoice" (saveVoice)="saveVoice($event)"></app-voice-transaction>

