<section class="section">
    <div class="container">
        <div> 
            <h1 class='title has-text-centered'> 
                Estimates
                <button class="button is-primary" style="float:right" (click)="addEstimate()">Add</button> 
            </h1> 
            
        </div> 
    </div>
</section>
<section class="section">
    <div class="container">
        <div class='columns is-mobile is-centered'> 
            <div class='column'> 
                <div class='list'> 
                    <ul> 
                        <li *ngFor="let estimate of estimates" (click)="editEstimate(estimate)"> 
                            {{estimate.name}} 
                            <span style="float:right">{{estimate.amount | currency}}</span>
                        </li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<app-estimate-detail [estimate]="estimate" *ngIf="showDetails" (closeDialog)="toggleDetails()"></app-estimate-detail>