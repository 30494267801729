import { Component, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['../../node_modules/bulma/css/bulma.css','./app.component.css']
})
export class AppComponent {
  title = 'T4 Budget';
  name: any;
  @ViewChild('burger') navBurger!: ElementRef;
  @ViewChild('navMenu') navMenu!: ElementRef;

  constructor(public authService: AuthService) {

  }

  logout(): void {
    this.authService.logout();
  }

  toggleNavbar(): void {
    this.navBurger.nativeElement.classList.toggle('is-active');
    this.navMenu.nativeElement.classList.toggle('is-active');
  }

}
