<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Voice Transaction</p>
        <button class="delete" aria-label="close" (click)="close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
            <label class="label">Transaction</label>
            <div class="control">
                <input type="text" class="input is-primary" [(ngModel)]="cmd" id="cmd" style="width:100%; height:3em" (input)="listenForSave()"/>
            </div>
        </div>         
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="translate()">Save</button>
        <button class="button" (click)="close()">Cancel</button>
      </footer>
    </div>
  </div>