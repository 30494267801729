import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, from} from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Estimate } from './estimate';
import { Transaction } from './transaction';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  transactionRef: AngularFireList<any>;

  constructor(private db: AngularFireDatabase) {
    this.transactionRef=db.list("/transactions");
   }

   addTransaction(transaction: Transaction): Observable<any> {
     return from(this.transactionRef.push(transaction)).pipe(first());
   }
   deleteTransaction(id: string): void {
    this.db.object(`/transactions/${id}`).remove();
  }
   updateTransaction(id: string, updates: any): void {
     this.db.object(`/transactions/${id}`).update(this.stripId(updates));
   }

  retrieveTransaction(id: string): Observable<any> {
    return this.db.object(`/transactions/${id}`).valueChanges();
  }

  getTransactionsForAccount(accountId: string): Observable<any[]> {
    
    return this.db.list(`/transactions`,ref=>ref.orderByChild("accountId").equalTo(accountId)).snapshotChanges().pipe(
      map((changes: any[]) => 
        changes.map(c => ({ id: c.payload.key, ...c.payload.val() }))
        
      )
    );
  }

  stripId(trans: Object):any {
    let fbAcct: any={...trans};
    delete fbAcct.id;
    return fbAcct;
  }


  
}
