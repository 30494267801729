import { Component, OnInit } from '@angular/core';
import { Estimate } from '../estimate';
import { EstimateService } from '../estimate.service';

@Component({
  selector: 'app-estimates',
  templateUrl: './estimates.component.html',
  styleUrls: ['./estimates.component.css']
})
export class EstimatesComponent implements OnInit {
  estimate: Estimate;
  estimates: Estimate[]=[];
  showDetails: boolean = false;
  constructor(private estimateService:EstimateService) { 
    this.estimate=estimateService.generateEstimate();   
    this.estimateService.getEstimates().subscribe(estimates=>{
      this.estimates=estimates.sort((a,b)=>{
        if (a.name>b.name) return 1;
        if (a.name<b.name) return -1;
        return 0;
      });
    });
 
  }

  toggleDetails(): void {
    this.showDetails=!this.showDetails;
  }
  addEstimate(): void {
    this.estimate=this.estimateService.generateEstimate();
    this.toggleDetails();
  }
  editEstimate(estimate: Estimate): void {
    this.estimate=estimate;
    this.toggleDetails();
  }
  ngOnInit(): void {
  }

}
