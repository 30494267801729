<section class="section">
    <div class="container">
        <div> 
            <h1 class='title has-text-centered'> 
                Accounts
                <button class="button is-primary" style="float:right" (click)="addAccount()">Add</button> 
            </h1> 
            
        </div> 
    </div>
</section>
<section class="section">
    <div class="container">
        <div class='columns is-mobile is-centered'> 
            <div class='column'> 
                <div class='list'> 
                    <ul> 
                        <li *ngFor="let account of accounts" (click)="editAccounts(account)"> 
                            {{account.name}} 
                            <fa-icon [icon]="faCheck" *ngIf="account.default"></fa-icon>
                        </li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="modal is-active" *ngIf="showAddDialog">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add/Edit Account</p>
        <button class="delete" aria-label="close" (click)="toggleAddDialog()"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
            <label class="label">Name</label>
            <div class="control">
                <input class="input is-primary" type="text" [(ngModel)]="account.name"/>
            </div>
        </div>
        <div class="field">
            <label class="label">Starting Balance</label>
            <div class="control">
                <input type="number" class="input is-primary number1" [(ngModel)]="account.startingBalance"/>
            </div>
        </div>
        <div class="field">
            <label class="label">Default</label>
            <div class="control">
                <input type="checkbox" class="is-primary" [(ngModel)]="account.default"/>
            </div>
        </div>  
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" (click)="saveAccount()">Save</button>
        <button class="button is-danger" (click)="deleteAccount()" *ngIf="account.id!=null">Delete</button>
        <button class="button" (click)="toggleAddDialog()">Cancel</button>
      </footer>
    </div>
  </div>