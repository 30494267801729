import { Component, OnInit } from '@angular/core';
import { Transaction } from '../transaction';
import { Data } from '../data';
import { AccountService } from '../account-service';
import { Account } from '../account';
import { TransactionService } from '../transaction-service';
import { faMicrophoneAlt} from '@fortawesome/free-solid-svg-icons'
@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['../../../node_modules/bulma/css/bulma.css','./transaction.component.css']
})
export class TransactionComponent implements OnInit {
  transaction: Transaction;
  dpConfig= {format:"M/D/YYYY"};
  selectedDate: any;
  data: any;
  inputDate: string = new Date().toLocaleDateString();
  accounts: Account[]=[];
  savingData: boolean = false;
  errorMessage: string | null= null;
  microphone = faMicrophoneAlt;
  showVoice:boolean = false;

  toggleVoice(): void {
    this.showVoice=!this.showVoice;
  }

  saveVoice(obj: any): void {
    this.transaction.amount=obj.amount;
    this.transaction.payee=obj.payee;
    this.transaction.type=obj.type;
    this.inputDate=new Date().toLocaleDateString();
    this.saveTransaction();
    this.toggleVoice();
    
  }

  resetTrans(): void {
    this.transaction.amount=null;
    this.transaction.payee=null;
    this.transaction.type='P';
    this.transaction.accountId=null;
    this.transaction.toAccountId=null;
    this.transaction.cleared=false;
    for (let acct of this.accounts){
      if (acct.default) {
        this.transaction.accountId=acct.id;
        break;
      }
    }
  }
  
  constructor(private accountService: AccountService, private tranService: TransactionService) { 
    let date = new Date().getTime();
    this.transaction={id:null, date:date,amount:null, payee:null, type:'P', accountId:null, toAccountId:null, cleared: false};
    this.data=new Data();
    //https://budget-6c9b0.firebaseio.com/transactions/-MMrYTXot0D1HAAFyXTz
    //tranService.retrieveTransaction("-MMrYTXot0D1HAAFyXTz").subscribe(o=>console.log(o));
  }

  ngOnInit(): void {
    this.accountService.getAccounts().subscribe(accounts=>{
      this.accounts=accounts
      if (this.transaction==null || this.transaction.accountId==null){
        let def: Account = accounts.reduce(
          (def, current)=>{
            if (current.default) {
              return current;
            }
            return def;
          }
        );
        this.transaction.accountId=def?def.id:null;
      } 
    });
  }

  saveTransaction(): void {
    if (this.transaction.amount==null || this.transaction.amount===0.0) {
      this.errorMessage="Enter an amount";
      return;
    }

    if (isNaN(this.transaction.amount)) {
      this.errorMessage="Invalid amount";
      return;
    }
    if (this.inputDate==null){
      this.errorMessage="Select a date";
      return;
    }
    if (this.transaction.type==="T" && this.transaction.toAccountId===null){
      this.errorMessage="Select a destination account";
      return;
    }
    if (this.transaction.type==="T" && this.transaction.toAccountId===this.transaction.accountId){
      this.errorMessage="To and from accounts must be different";
      return;
    }
    this.savingData=true;
    this.transaction.date=new Date(this.inputDate).getTime();

    if (this.transaction.type==="T") {
      //Just work in payments and deposits, create the other side
      let to = {...this.transaction};
      to.type="D";
      to.accountId=this.transaction.toAccountId!;
      this.tranService.addTransaction(to).subscribe();
      this.transaction.toAccountId=null;
      this.transaction.type="P";
    }
    this.tranService.addTransaction(this.transaction).subscribe(o=>{
        this.savingData=false;
        this.resetTrans();
      },
      err=>{
        this.errorMessage=err;
        this.savingData=false;
      }
    )
  }

  clearError(): void {
    this.errorMessage=null;
  }
  readyToSave(): boolean {
    return this.transaction!=null
            && this.transaction.payee!=null
            && this.transaction.date!=null
            && this.transaction.accountId!=null
            && this.transaction.type!=null
            && (this.transaction.type!="T"
                ||
                (
                  this.transaction.type==="T"
                  && this.transaction.toAccountId!=null
                )
            );
  }

}
